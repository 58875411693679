import {useEffect, useState} from 'react'
import axios, {AxiosError} from 'axios'
import {ITranslation, ITranslationInfo, ITranslationPair, IUserChoice} from "../models/translationInfo";
import {useChangeWordMutation} from "../store/vocabulary/vocabulary.api";
import {IWordEntity, Status} from "../models/vocabulary";


export function useCardBuilderInfo(word: string) {


    const [changeWord] = useChangeWordMutation();
    const [translationInfo, setTranslationInfo] = useState<ITranslationInfo>()
    const [userChoice, setUserChoice] = useState<IUserChoice>({
        image: undefined,
        examples: [],
        phrasalVerbs: [],
        phrases: [],
        possibleCognates: [],
        translations: [],
    })


    async function saveVocabularyWord(status: Status): Promise<IWordEntity> {
        if (
            userChoice.translations!!.length > 0 &&
            userChoice.examples!!.length > 0
        ) {
            const req: IWordEntity = {
                status: status,
                userChoice: userChoice,
                word: word
            }
            return changeWord(req).unwrap()
        }
        return new Promise((resolve, reject) => {
            reject(new Error('Need more info for save card'));
        });
    }


    function setImage(url: string) {
        setUserChoice(prevState => {
            return {...prevState, image: url}
        })
    }

    function setTranslations(translations: ITranslation[]) {
        setUserChoice(prevState => {
            return {...prevState, translations: translations}
        })
    }

    function setExamples(examples: ITranslationPair[]) {
        setUserChoice(prevState => {
            return {...prevState, examples: examples}
        })
    }

    function setPhrases(phrases: ITranslationPair[]) {
        setUserChoice(prevState => {
            return {...prevState, phrases: phrases}
        })
    }

    function setPhrasalVerb(phrasalVerbs: ITranslationPair[]) {
        setUserChoice(prevState => {
            return {...prevState, phrasalVerbs: phrasalVerbs}
        })
    }

    function setPossibleCognates(cognates: ITranslationPair[]) {
        setUserChoice(prevState => {
            return {...prevState, possibleCognates: cognates}
        })
    }

    async function fetchTranslationInfo(query: string) {
        try {
            const response = await axios.get<ITranslationInfo>('/api/translation/word/' + query)
            setTranslationInfo(() => response.data)
            setUserChoice({
                ...userChoice,
                pronunciationUrl: response.data.pronunciationUrl,
                transcription: response.data.transcription,
                irregularVerbInfo: response.data.irregularVerbInfo,
            })
        } catch (e: unknown) {
            const error = e as AxiosError
            alert(error.message)
        }
    }


    useEffect(() => {
        fetchTranslationInfo(word)
    }, [])

    return {
        translationInfo,
        setImage,
        setTranslations,
        setExamples,
        setPhrases,
        setPhrasalVerb,
        setPossibleCognates,
        saveVocabularyWord
    }
}
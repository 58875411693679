import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {IWordEntity} from "../../models/vocabulary";
import {AnkiConnectAddCardRequest, PrepareAddCardRequest} from "../../models/cardBuilder";

export const cardBuilderApi = createApi({

    reducerPath: 'cardbuilder/api',

    baseQuery: fetchBaseQuery({
        baseUrl: '/api/cardbuilder/'
    }),

    endpoints: build => ({
        getAnkiConnectRequest: build.mutation<AnkiConnectAddCardRequest, IWordEntity>({
            query: (word: IWordEntity) => ({
                url: `card`,
                method: `POST`,
                body: mapWordEntityToPrepareAddCardRequest(word)
            }),
        })
    })

})


function mapWordEntityToPrepareAddCardRequest(entity: IWordEntity): PrepareAddCardRequest {
    const userChoice = entity.userChoice || {};
    return {
        english: entity.word,
        translations: userChoice.translations || [],
        examples: userChoice.examples || [],
        transcription: userChoice.transcription || null,
        verbForms: entity.userChoice?.irregularVerbInfo,
        phrases: userChoice.phrases || [],
        phrasalVerbs: userChoice.phrasalVerbs || [],
        cognates: userChoice.possibleCognates || [],
        pronunciationSound: userChoice.pronunciationUrl || null,
        image: userChoice.image || null
    };
}

export const {useGetAnkiConnectRequestMutation} = cardBuilderApi

import React from 'react';

interface FloatingButtonsProps {
    onGreenButtonClick: () => void;
    onBlueButtonClick: () => void;
}

function AddButtons({onGreenButtonClick, onBlueButtonClick}: FloatingButtonsProps) {
    return (
        <div className="fixed bottom-4 right-4 flex space-x-2">
            <button
                className="w-12 h-12 bg-green-500 rounded-full flex items-center justify-center text-white hover:bg-green-600 active:bg-green-700 focus:outline-none"
                onClick={onGreenButtonClick}
            >
                ANKI
            </button>
            <button
                className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center text-white hover:bg-blue-600 active:bg-blue-700 focus:outline-none"
                onClick={onBlueButtonClick}
            >
                SAVE
            </button>
        </div>
    );
}

export default AddButtons;

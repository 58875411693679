import React, {useState} from 'react';
import {ITranslationPair} from "../models/translationInfo";


interface InlineTranslationProps {
    title: string,
    items: ITranslationPair[];
    onSelect: (items: ITranslationPair[]) => void
}

function OriginalToTranslateBlock({title, items, onSelect}: InlineTranslationProps) {
    const [selectedItems, setSelectedItems] = useState<ITranslationPair[]>([]);

    const handleCheckboxChange = (changeItem: ITranslationPair) => {
        setSelectedItems((prevSelected) => {

            if (prevSelected.includes(changeItem)) {
                return prevSelected.filter((item) => item !== changeItem)
            } else {
                return [...prevSelected, changeItem]
            }
        })
        onSelect(selectedItems)
    };


    return (
        <div className="p-4">
            <div className="header mb-2">
                <strong>{title}:</strong>
            </div>
            {items.map((item, index) => (
                <div key={item.original} className="mb-3 group">
                    <label htmlFor={`translation-${item.original}`} className="cursor-pointer block">
                        <input
                            type="checkbox"
                            id={`translation-${item.original}`}
                            className="form-checkbox h-5 w-5 text-blue-600 rounded absolute mt-1 ml-1"
                            checked={selectedItems.includes(item)}
                            onChange={() => handleCheckboxChange(item)}
                        />
                        <span className="pl-8 block font-semibold">{item.original}</span>
                        <span className="pl-8 block italic text-gray-700">{item.translate}</span>
                    </label>
                    <span className="p-1 rounded-bl absolute top-0 right-0">
          </span>
                </div>
            ))}
        </div>
    );
}

export default OriginalToTranslateBlock
import React, {useEffect, useState} from "react";
import VocabularyTable from "../components/VocabularyTable";
import {useAddNewWordMutation, useDeleteWordMutation, useGetWordsQuery} from "../store/vocabulary/vocabulary.api";
import {IWordEntity} from "../models/vocabulary";
import {useNavigate} from "react-router-dom";

export function Vocabulary() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Vocabulary';
    }, []);

    const {isLoading, isError, data, refetch} = useGetWordsQuery(null, {
        refetchOnMountOrArgChange: true
    })
    const words: IWordEntity[] = data || []


    const [newWord, setNewWord] = useState('')
    const [addWord] = useAddNewWordMutation()
    const [deleteWord] = useDeleteWordMutation()


    const handleDeleteWordClick = (wordEntry: IWordEntity) => {
        deleteWord(wordEntry.word)
            .then(() => {
                refetch()
            })
    };

    const handleAddNewWordClick = () => {
        addWord({word: newWord})
            .then(() => {
                setNewWord('')
                refetch()
            })

    };

    const handleNewWordInputKeyPressDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {  // 13 is the key code for Enter
            handleAddNewWordAndBuildCardClick()
        }
    }


    const handleAddNewWordAndBuildCardClick = async () => {

        addWord({word: newWord})
            .then(() => {
                setNewWord('')
                refetch()
                navigate('/card/' + newWord)
            })
    };


    return (

        <div className="p-6 bg-gray-100 h-screen">
            <div className="max-w-6xl mx-auto bg-gray-50 p-4 sm:p-6 lg:p-8">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold">Vocabulary</h2>

                    <button
                        onClick={() => navigate('/settings')}
                        className="bg-gray-200 hover:bg-gray-400 text-white p-3 px-4 mr-2 rounded transition ease-in-out duration-150"
                    >
                        <span role="img" aria-label="Settings">⚙️</span>
                    </button>
                </div>


                <div className="mb-4 flex">
                    <input
                        type="text"
                        value={newWord}
                        onChange={e => setNewWord(e.target.value)}
                        onKeyDown={(e) => handleNewWordInputKeyPressDown(e)}
                        placeholder="New word..."
                        className="flex-grow p-2 border rounded-lg mr-2"
                    />
                    <button
                        className="bg-blue-300 hover:bg-blue-700 text-white py-1 px-3 rounded transition ease-in-out duration-150"
                        onClick={handleAddNewWordClick}
                    >
                        Add
                    </button>
                    <button
                        className="mx-2 bg-green-300 hover:bg-green-700 text-white py-1 px-3 rounded transition ease-in-out duration-150"
                        onClick={handleAddNewWordAndBuildCardClick}
                    >
                        Build
                    </button>
                </div>

                <VocabularyTable
                    data={words}
                    onDeleteClick={handleDeleteWordClick}
                />
            </div>
        </div>

    );

}
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {IWordEntity} from "../../models/vocabulary";
import {AnkiConnectAddCardRequest, PrepareAddCardRequest} from "../../models/cardBuilder";


interface IAnkiConnectRequestProp {
    ankiUrl: string,
    request: AnkiConnectAddCardRequest,
}

export const ankiConnectApi = createApi({

    reducerPath: 'anki-connect/api',

    baseQuery: fetchBaseQuery(),

    endpoints: build => ({
        buildCard: build.mutation<boolean, IAnkiConnectRequestProp>({
            query: ({ankiUrl, request}: IAnkiConnectRequestProp) => ({
                url: ankiUrl,
                method: `POST`,
                body: request
            }),
            transformResponse: (response: any, meta: any) => {
                return meta.response && meta.response.status >= 200 && meta.response.status < 300;
            }
        })
    })

})



export const {useBuildCardMutation} = ankiConnectApi

import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import thunk from 'redux-thunk'
import {vocabularyApi} from "./vocabulary/vocabulary.api";
import settingsReducer from "./settings/settings";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {cardBuilderApi} from "./cardBuilder/cardBuilder.api";
import {ankiConnectApi} from "./cardBuilder/ankiConnect.api";

export const store = configureStore({
    reducer: {
        [vocabularyApi.reducerPath]: vocabularyApi.reducer,
        [cardBuilderApi.reducerPath]: cardBuilderApi.reducer,
        [ankiConnectApi.reducerPath]: ankiConnectApi.reducer,
        settings: settingsReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(thunk)
            .concat(vocabularyApi.middleware)
            .concat(cardBuilderApi.middleware)
            .concat(ankiConnectApi.middleware)
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
import {IUserChoice} from "./translationInfo";

export interface IWordEntity {
    word: string;
    status?: Status;
    addedDate?: Date;
    userChoice?: IUserChoice | null;
}


export enum Status {
    NEW = "NEW",
    CARD_PREPARED = "CARD_PREPARED",
    CARD_ADDED = "CARD_ADDED"
}
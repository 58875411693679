import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Vocabulary} from "./pages/Vocabulary";
import {CardBuilder} from "./pages/CardBuilder";
import SettingsPage from "./pages/Settings";
import {useDispatch} from "react-redux";
import {loadSettingsApi} from "./store/settings/settings";
import {AppDispatch} from "./store";


function App() {

    const dispatch: AppDispatch = useDispatch();
    dispatch(loadSettingsApi());

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">

                    {/*with redux*/}
                    <Route index element={<Vocabulary />} />
                    <Route path="/settings" element={<SettingsPage />} />

                    {/*hooks without redux*/}
                    <Route path="card/:query" element={<CardBuilder />} />

                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;

import Translations from "../components/Translations";
import ImageSelector from "../components/ImageSelector";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useCardBuilderInfo} from "../hooks/cardBuilderInfo";
import OriginalToTranslateBlock from "../components/OriginalToTranslateBlock";
import AddButtons from "../components/AddButtons";
import {IWordEntity, Status} from "../models/vocabulary";
import {useGetAnkiConnectRequestMutation} from "../store/cardBuilder/cardBuilder.api";
import {AnkiConnectAddCardRequest} from "../models/cardBuilder";
import {useBuildCardMutation} from "../store/cardBuilder/ankiConnect.api";
import {useAppSelector} from "../store";


export function CardBuilder() {

    const settings = useAppSelector(state => state.settings.settings);
    const navigate = useNavigate();
    const {query} = useParams<string>();
    const [getAnkiConnectRequestTrigger] = useGetAnkiConnectRequestMutation()
    const [buildCardTrigger] = useBuildCardMutation()

    const {
        translationInfo,
        setImage,
        setPossibleCognates,
        setPhrasalVerb,
        setPhrases,
        setExamples,
        setTranslations,
        saveVocabularyWord,
    } = useCardBuilderInfo(query!!)


    useEffect(() => {
        document.title = query!! + ' - Vocabulary';
    }, []);


    const handleAnkiSaveCardButtonClick = async () => {
        try {
            const saved: IWordEntity = await saveVocabularyWord(Status.CARD_ADDED);
            const ankiRequest: AnkiConnectAddCardRequest = await getAnkiConnectRequestTrigger(saved).unwrap();
            const success: boolean = await buildCardTrigger({ankiUrl: settings.ankiConnectUrl, request: ankiRequest}).unwrap();
            if (success) {
                navigate("/");
            }
        } catch (error) {
            console.error("Error handling green button click:", error);
        }
    };

    return (
        <div className="max-w-6xl mx-auto p-4 sm:p-0">
            <h2 className="text-2xl px-4 mt-5 font-semibold mb-1">{query}</h2>

            {translationInfo?.transcription &&
                <div className="group py-1 px-4 mb-1 relative block text-gray-500 italic">
                    {translationInfo.transcription}
                </div>
            }

            {translationInfo?.irregularVerbInfo &&
                <div className="group px-4 py-1 mb-1 relative block text-gray-700">
                    {translationInfo.irregularVerbInfo}
                </div>
            }

            {translationInfo?.translations &&
                <Translations
                    translations={translationInfo.translations}
                    onSelect={setTranslations}
                />
            }

            {translationInfo?.images &&
                <ImageSelector
                    images={translationInfo.images}
                    onSelect={setImage}
                />
            }

            {translationInfo?.examples &&
                <OriginalToTranslateBlock
                    title='Exmaples'
                    items={translationInfo.examples}
                    onSelect={setExamples}
                />
            }

            {translationInfo?.phrasalVerbs && translationInfo.phrasalVerbs.length > 0 &&
                <OriginalToTranslateBlock
                    title='Phrasal verbs'
                    items={translationInfo.phrasalVerbs}
                    onSelect={setPhrasalVerb}
                />
            }

            {translationInfo?.phrases && translationInfo.phrases.length > 0 &&
                <OriginalToTranslateBlock
                    title='Phrases'
                    items={translationInfo.phrases}
                    onSelect={setPhrases}
                />
            }

            {translationInfo?.possibleCognates && translationInfo.possibleCognates.length > 0 &&
                <OriginalToTranslateBlock
                    title='Possible cognates'
                    items={translationInfo.possibleCognates}
                    onSelect={setPossibleCognates}
                />
            }

            <AddButtons
                onGreenButtonClick={handleAnkiSaveCardButtonClick}

                onBlueButtonClick={() => {
                    saveVocabularyWord(Status.CARD_PREPARED)
                        .then(() => {
                            navigate("/")
                        })
                }}/>
        </div>
    );
}
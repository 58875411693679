import React, {ChangeEvent, useState} from 'react';


interface ImageSelectorProps {
    images: string[]
    onSelect: (url: string) => void
}

function ImageSelector({images, onSelect}: ImageSelectorProps) {
    const [selectedImage, setSelectedImage] = useState<string>();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedImage(e.target.value);
        onSelect(e.target.value)
    }

    return (
        <div className="grid grid-cols-3 gap-2 p-4 h-[50vh] overflow-y-auto">
            {images.map((imageUrl, index) => (
                <label key={imageUrl} htmlFor={`image-${index}`} className="relative group block cursor-pointer">
                    <img src={imageUrl} className="w-full h-full object-contain rounded" alt={`image-${index}`}/>
                    <input
                        type="radio"
                        id={`image-${index}`}
                        name="image"
                        className="opacity-0 absolute cursor-pointer top-0 right-0 h-8 w-8"
                        value={imageUrl}
                        checked={selectedImage === imageUrl}
                        onChange={handleChange}
                    />
                    <span className="absolute top-0 right-0 p-1 bg-white rounded-bl">
                      <span
                          className={`group-hover:text-blue-500 ${selectedImage === imageUrl ? 'text-green-500' : 'text-gray-400'}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                             className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"></path>
                        </svg>
                      </span>
                    </span>
                </label>
            ))}
        </div>
    );

}

export default ImageSelector;

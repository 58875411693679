import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {IWordEntity} from "../../models/vocabulary";

export const vocabularyApi = createApi({

    reducerPath: 'vocabulary/api',

    baseQuery: fetchBaseQuery({
        baseUrl: '/api/vocabulary/'
    }),

    endpoints: build => ({
        getWords: build.query<IWordEntity[], null>({
            query: () => ({
                url: `words`,
            }),
        }),

        addNewWord: build.mutation<null, IWordEntity>({
            query: (word: IWordEntity) => ({
                url: `words`,
                method: 'POST',
                body: word,
            }),
        }),

        deleteWord: build.mutation<null, string>({
            query: (word: string) => ({
                url: `words/${word}`,
                method: 'DELETE'
            }),
        }),

        changeWord: build.mutation<IWordEntity, IWordEntity>({
            query: (word: IWordEntity) => ({
                url: `words/${word.word}`,
                method: 'POST',
                body: word
            }),
        }),
    })

})

export const {
    useGetWordsQuery,
    useAddNewWordMutation,
    useDeleteWordMutation,
    useChangeWordMutation
} = vocabularyApi

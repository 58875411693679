import React, {useEffect, useState} from 'react';
import {ITranslation} from "../models/translationInfo";

interface TranslationProps {
    translations: ITranslation[];
    onSelect: (selection: ITranslation[]) => void
}

function Translations({translations, onSelect}: TranslationProps) {
    const [selectedTranslations, setSelectedTranslations] = useState<ITranslation[]>([]);

    useEffect(() => {
        onSelect(selectedTranslations);
    }, [selectedTranslations]);

    const handleCheckboxChange = (translation: ITranslation) => {
        setSelectedTranslations((prevSelected) => {
            let selectedTranslations
            if (prevSelected.includes(translation)) {
                selectedTranslations = prevSelected.filter((item) => item !== translation)
            } else {
                selectedTranslations = [...prevSelected, translation];
            }
            return selectedTranslations
        })
    };

    return (
        <div className="p-4">
            <div className="flex flex-wrap">
                {translations.map((translation, index) => (
                    <div key={translation.translation + translation.frequency}
                         className="group mb-3 mr-3 relative inline-block">
                        <label htmlFor={`translation-${translation.translation + index}`} className="cursor-pointer">
                            <input
                                type="checkbox"
                                id={`translation-${translation.translation + index}`}
                                className="form-checkbox h-5 w-5 text-blue-600 rounded absolute mt-1 ml-1"
                                checked={selectedTranslations.includes(translation)}
                                onChange={() => handleCheckboxChange(translation)}
                            />
                            <span className="pl-8 inline-block">
                <span className="word-translation">{translation.translation}</span>
                                {translation.frequency &&
                                    <span className="frequency text-xs">({translation.frequency})</span>}
              </span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Translations;

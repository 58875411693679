import type {PayloadAction} from '@reduxjs/toolkit'
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

const LS_SETTINGS_KEY = 'settings'

interface ISettings {
    ankiConnectUrl: string
}

interface SettingsState {
    settings: ISettings
}


const initialState: SettingsState = {
    settings: {
        ankiConnectUrl: ''
    }
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        changeAnkiUrl(state, action: PayloadAction<string>) {
            state.settings.ankiConnectUrl = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadSettingsApi.fulfilled, (state, action: PayloadAction<ISettings>) => {
            state.settings = action.payload;
        }).addCase(saveSettingsApi.fulfilled, (state, action) => {
            state.settings = action.payload
        });
    }
})


async function getSettingsFromServer() {
    const response = await axios.get<ISettings>("/api/cardbuilder/settings")
    return response.data
}

export const loadSettingsApi = createAsyncThunk(
    'settings/load',
    async () => {
        return getSettingsFromServer()
    }
);

export const saveSettingsApi = createAsyncThunk('settings/save', async (settings: ISettings) => {
    await axios.put('/api/cardbuilder/settings', settings);
    return settings
});

export const {changeAnkiUrl} = settingsSlice.actions
export default settingsSlice.reducer
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../store";
import {changeAnkiUrl, saveSettingsApi} from "../store/settings/settings";

function SettingsPage() {
    const settings = useAppSelector(state => state.settings.settings);
    const dispatch = useAppDispatch();

    const handleSaveClick = () => {
        dispatch(saveSettingsApi(settings))
    }

    return (
        <div className="p-6 bg-gray-100 h-screen">
            <div className="max-w-6xl mx-auto bg-gray-50 p-4 sm:p-6 lg:p-8">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold">Settings</h2>
                </div>

                <div className="mb-4">
                    <label htmlFor="ankiConnectURL" className="block text-sm font-medium text-gray-700 mb-2">
                        AnkiConnect URL
                    </label>
                    <input
                        id="ankiConnectURL"
                        type="text"
                        value={settings.ankiConnectUrl}
                        onChange={e => dispatch(changeAnkiUrl(e.target.value))}
                        placeholder="http://localhost:8765"
                        className="flex-grow p-2 border rounded-lg w-full"
                    />
                </div>

                <div className="mt-6">
                    <button
                        onClick={handleSaveClick}
                        className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition ease-in-out duration-150"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SettingsPage;
import React from 'react';
import {IWordEntity, Status} from "../models/vocabulary";
import {useNavigate} from 'react-router-dom';

interface VocabularyTableProps {
    data: IWordEntity[];
    onDeleteClick: (word: IWordEntity) => void;
}

function VocabularyTable({data, onDeleteClick}: VocabularyTableProps) {
    const navigate = useNavigate();

    const getBackgroundColorForCard = (status?: Status) => {
        switch (status) {
            case Status.NEW:
                return "bg-blue-50";
            case Status.CARD_PREPARED:
                return "bg-yellow-50";
            case Status.CARD_ADDED:
                return "bg-green-50";
            default:
                return "";
        }
    }

    const getStatusName = (status?: Status) => {
        switch (status) {
            case Status.NEW:
                return "New";
            case Status.CARD_PREPARED:
                return "Prepared";
            case Status.CARD_ADDED:
                return "Added";
            default:
                return "";
        }
    }

    return (


        <div className="space-y-4">
            {data.map((wordItem, index) => (
                <div key={index}
                     className={`p-4 rounded-lg shadow-sm flex justify-between space-y-2 sm:space-y-0 ${getBackgroundColorForCard(wordItem.status)}`}>
                    <div className="flex-grow">
                        <p className="text-xl">{wordItem.word}</p>
                    </div>
                    <div className="flex flex-col items-end space-y-2">
                            <span
                                className={`text-xs text-gray-500 italic`}>
                                {getStatusName(wordItem.status)}
                            </span>
                        <div className="flex space-x-4">
                            <button onClick={() => navigate("/card/" + wordItem.word)}
                                    className="text-white py-1 px-3 rounded bg-blue-600 hover:bg-blue-700 transition ease-in-out duration-150">
                                Build
                            </button>
                            <button
                                onClick={() => onDeleteClick(wordItem)}
                                className="text-white py-1 px-3 rounded bg-red-600 hover:bg-red-700 transition ease-in-out duration-150">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>

    );
}

export default VocabularyTable;
